import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { WithChildren } from "../helpers/with-children";
import { ApplicationError } from "../pages/application-error/applicationError";
// import Loader from "../pages/common/loader/loader";
import { MasterLayout } from "../pages/layouts";
import { SplashScreen } from "../pages/layouts/core/SplashScreen";
import CustomerTrucks from "../pages/configurations/customers/components/CustomerTrucks";
// import UserPermissionService from "../providers/permissions/user-permissions-service";
// import ProtectedRoute from "./ProtectedRoutes";

const PrivateRoutes = () => {
  // const user = UserPermissionService();

  // if (!user?.permissions) {
  //   return <Loader />;
  // }

  const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
  const Page401 = lazy(() => import("../pages/auth/components/401/page401"));
  const Page404 = lazy(() => import("../pages/auth/components/404/page404"));

  const Configurations = lazy(
    () => import("../pages/configurations/Configurations")
  );

  const Reporting = lazy(() => import("../pages/reporting/Reports"));

  const Payments = lazy(() => import("../pages/payments/components/Payments"));


  const Companies = lazy(
    () => import("../pages/configurations/companies/components/Companies")
  );

  const Accounts = lazy(
    () => import("../pages/configurations/accounts/components/Accounts")
  );

  const Locations = lazy(
    () => import("../pages/configurations/locations/components/Locations")
  );

  const Customers = lazy(
    () => import("../pages/configurations/customers/components/Customers")
  );
  const CustomerDetail = lazy(
    () => import("../pages/configurations/customers/components/CustomerDetail")
  );

  const LinkedAccounts = lazy(
    () => import("../pages/configurations/customers/components/LinkedAccounts")
  );

  const ContentManagement = lazy(
    () => import("../pages/content-management/ContentManagement")
  );

  const SalesVouchers = lazy(
    () => import("../pages/sales-vouchers/components/SalesVouchers")
  );
  const UserSalesVoucherDetails = lazy(
    () => import("../pages/sales-vouchers/components/UserSalesVoucherDetail")
  );

  const BanksMasterData = lazy(
    () => import("../pages/configurations/banks/components/BanksMasterData")
  );
  const SettleMentBanks = lazy(
    () =>
      import(
        "../pages/configurations/setllement-banks/components/SettleMentBanks"
      )
  );
  const ProductConfiguration = lazy(
    () =>
      import(
        "../pages/configurations/products-configuration/components/ProductConfiguration"
      )
  );

  const ManualPayment = lazy(
    () => import("../pages/manual-payments/components/ManualPayment")
  );

  const SalesVouchersReport = lazy(
    () => import("../pages/reporting/components/salesvouchers/SalesVouchers")
  );

  const ManualPayments = lazy(
    () => import("../pages/reporting/components/manual-payments/ManualPayments")
  );
  const Delivery = lazy(
    () => import("../pages/reporting/components/delivery/Delivery")
  );
  const Users = lazy(() => import("../pages/reporting/components/users/Users"));


  return (
    <Routes>
      <Route path="application-error" element={<ApplicationError />} />
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="" element={<Navigate to="/landing" />} />
        <Route
          path="landing"
          element={
            <SuspensedView>
              <Dashboard />
            </SuspensedView>
          }
        />
        <Route
          path="401"
          element={
            <SuspensedView>
              <Page401 />
            </SuspensedView>
          }
        />

        <Route
          path="404"
          element={
            <SuspensedView>
              <Page404 />
            </SuspensedView>
          }
        />
        <Route
          path="reports"
          element={
            <SuspensedView>
              <Reporting />
            </SuspensedView>
          }
        />
        <Route
          path="reports/sales-vouchers"
          element={
            <SuspensedView>
              <SalesVouchersReport />
            </SuspensedView>
          }
        />
        <Route
          path="reports/manual-payments"
          element={
            <SuspensedView>
              <ManualPayments />
            </SuspensedView>
          }
        />
        <Route
          path="reports/delivery"
          element={
            <SuspensedView>
              <Delivery />
            </SuspensedView>
          }
        />
        <Route
          path="reports/users"
          element={
            <SuspensedView>
              <Users />
            </SuspensedView>
          }
        />
        <Route
          path="configurations"
          element={
            <SuspensedView>
              <Configurations />
            </SuspensedView>
          }
        />
        <Route
          path="payments"
          element={
            <SuspensedView>
              <Payments />
            </SuspensedView>
          }
        />
        <Route
          path="configurations/companies"
          element={
            <SuspensedView>
              <Companies />
            </SuspensedView>
          }
        />
        <Route
          path="configurations/accounts"
          element={
            <SuspensedView>
              <Accounts />
            </SuspensedView>
          }
        />
        <Route
          path="configurations/locations"
          element={
            <SuspensedView>
              <Locations />
            </SuspensedView>
          }
        />
        <Route
          path="configurations/banks"
          element={
            <SuspensedView>
              <BanksMasterData />
            </SuspensedView>
          }
        />

        <Route
          path="configurations/settlement-banks"
          element={
            <SuspensedView>
              <SettleMentBanks />
            </SuspensedView>
          }
        />
        <Route
          path="configurations/products-configuration"
          element={
            <SuspensedView>
              <ProductConfiguration />
            </SuspensedView>
          }
        />
        <Route
          path="manual-payments/:paymentId"
          element={
            <SuspensedView>
              <ManualPayment />
            </SuspensedView>
          }
        />
        <Route
          path="orders"
          element={
            <SuspensedView>
              <SalesVouchers />
            </SuspensedView>
          }
        />

        <Route
          path={"orders-details/:id"}
          element={
            <SuspensedView>
              <UserSalesVoucherDetails />
            </SuspensedView>
          }
        />
        {/* <Route
          path="configurations/countries"
          element={
            <SuspensedView>
              <Countries />
            </SuspensedView>
          }
        /> */}

        <Route
          path="configurations/customers"
          element={
            <SuspensedView>
              <Customers />
            </SuspensedView>
          }
        />
        <Route
          path="configurations/customer/:id"
          element={
            <SuspensedView>
              <CustomerDetail />
            </SuspensedView>
          }
        />
        <Route
          path="configurations/customer/trucks/:id"
          element={
            <SuspensedView>
              <CustomerTrucks />
            </SuspensedView>
          }
        />
        <Route
          path="configurations/linked-accounts/:id"
          element={
            <SuspensedView>
              <LinkedAccounts />
            </SuspensedView>
          }
        />

        <Route
          path="content-management"
          element={
            <SuspensedView>
              <ContentManagement />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="/login" element={<Navigate to="/landing" />} />
        <Route path="*" element={<Navigate to="/404" />} />
        <Route path="*" element={<Navigate to="/landing" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView = ({ children }: WithChildren) => {
  const baseColor = "red";
  const newLocal = {
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  };
  TopBarProgress.config(newLocal);
  return <Suspense fallback={<SplashScreen />}>{children}</Suspense>;
};

export { PrivateRoutes };
